.account.page-print {
  font-size: 100%;
  line-height: normal;
  margin: 0;

  .logo {
    display: block;
    margin: spacers(4) 0;
  }

  .items-qty {
    margin: 0;
  }

  table {
    font-size: 100%;
  }

  tfoot,
  thead {
    border-top: $dashboard-block-title-border;
    border-bottom: $dashboard-block-title-border;
  }

  thead th,
  tfoot th {
    padding: spacers(2);
  }

  tfoot th {
    text-align: right;
    padding-right: spacers(3);
  }

  tbody tr {
    padding-left: 0;
    padding-right: 0;

    &:hover {
      box-shadow: none;
    }
  }

  tfoot td {
    padding-left: 0;
    padding-right: 0;
  }

  tbody td {
    padding: spacers(2) 0;

    &.col > span {
      width: auto;
    }

    .price-excluding-tax .price,
    .price-including-tax .price {
      font-size: inherit;
    }

    .price-excluding-tax:before,
    .price-including-tax:before {
      display: inline;
      top: 0;
    }
  }

  tbody ul,
  tbody dl,
  tbody ol,
  tbody .item-options {
    margin-bottom: 0;

    dt,
    dd {
      display: inline;
      margin: 0 spacers(2) 0 0;
    }
  }

  .page-title-wrapper {
    .page-title,
    .order-status {
      display: inline-block;
      font-size: 100%;
    }
  }

  .block-order-details-view {
    margin-top: 0;
  }

  .page-main .main .order-title {
    border-bottom: 0;
  }

  .order-title strong {
    display: block;
    @extend %u-heading-style;
    @include fluid-type($dashboard-block-title-text-fluid-size);
    padding-bottom: 0;
    font-weight: normal;
    margin-bottom: 0;
  }

  /* button actions not needed in print mode */
  .actions-toolbar {
    display: none;
  }
}
