// My Account pages content BG
.account:not(.page-print) {
  .column.main {
    padding-left: $container-padding;
    padding-right: $container-padding;
    background: $customer-account-light-bg;

    @include respond-to-up($page-layout-breakpoint) {
      padding-left: $page-layout-sidebar-spacing-x;
      padding-right: $page-layout-sidebar-spacing-x;
      width: calc(100% - 400px);
    }
  }

  .sidebar-container {
    background: $white;

    @include respond-to-up($page-layout-breakpoint) {
      position: sticky;
      top: 0;
      align-self: flex-start;
      margin-top: $page-layout-sidebar-spacing-x;
      margin-right: 0 !important;
      width: $customer-account-sidebar !important;
      max-width: 300px;
    }
  }

  .page-title {
    @extend %u-heading-style;
    @include fluid-type('h2');
    padding: $account-nav-padding;
    letter-spacing: 0;
    font-weight: normal;
    text-align: center;
    margin-right: spacers(4);

    @include respond-to-up(md) {
      text-align: left;
    }
  }

  .page-title-wrapper {
    .order-status,
    .page-title {
      display: inline-block;
    }
  }
}

%account-block-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 53px;
  margin-bottom: spacers(2);
  border-bottom: $dashboard-block-title-border;
  color: $dashboard-block-color;

  > strong {
    @extend %u-heading-style;
    @include fluid-type($dashboard-block-title-text-fluid-size);
    display: flex;
    align-items: center;
    padding-bottom: 0;
    font-weight: normal;
    letter-spacing: $dashboard-block-title-text-letter-spacing;

    &:before {
      color: $text-muted;
      margin-right: spacers(2);
    }
  }

  &.order > strong {
    @include ms-icon('shopping-cart', m);
  }
}

.account .page-main .main .block-title,
.account .page-main .main .order-title {
  @extend %account-block-title;
}

.account .page-main .block-title .action,
.account .page-main .block-content .action {
  font-size: 75%;
}

.account .box-actions .action {
  width: auto;
}

.account .item-edit-panel {
  width: 100%;

  @include respond-to-up(md) {
    width: auto;
  }

  > span {
    display: flex;
    text-transform: none;
  }
}

.account .page-main .actions-toolbar {
  margin-bottom: $dashboard-block-spacing-y;
}

// My Account Page Blocks
.block-addresses-default,
.block-addresses-list,
.block-order-details-view,
[class^='block-dashboard-'],
[class*=' block-dashboard-'] {
  color: $dashboard-block-color;
  margin-bottom: $dashboard-block-spacing-y;
  padding-bottom: $dashboard-block-spacing-y;

  .block-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    line-height: normal;

    .table-wrapper {
      width: 100%;
    }

    > .box {
      width: 100%;

      @include respond-to-up(md) {
        width: 50%;
      }

      @include respond-to-down($page-layout-breakpoint) {
        margin-bottom: spacers(4);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .box-title {
      @include fluid-type($dashboard-box-title-fluid-size);
      padding-bottom: spacers(2);
      display: block;

      @include respond-to-down(lg) {
        font-size: $font-size-base;
      }

      @include respond-to-up(lg) {
        font-weight: normal;
      }
    }

    .box-content {
      font-size: 90%;

      address,
      p {
        margin-bottom: 0;
      }
    }

    .box-actions {
      .action {
        padding: 0;
        font-size: 70%;
        margin-top: spacers(3);
        margin-right: spacers(4);
      }
    }
  }
}

.block-dashboard-addresses {
  > .block-title {
    strong {
      padding-right: spacers(3);
    }
    .action {
      padding-left: 0;
      padding-right: 0;

      @include respond-to-up(smm) {
        max-width: none;
      }
    }
  }
}

.form-address-edit,
.form-address-new {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;

  @include respond-to-up(lg) {
    flex-direction: row;
  }

  > .fieldset {
    @include respond-to-up(lg) {
      width: 50%;
    }

    &:nth-child(1) {
      @include respond-to-up(lg) {
        padding-right: $page-layout-sidebar-spacing-x * 0.5;
      }
    }

    &:nth-child(2) {
      @include respond-to-up(lg) {
        padding-left: $page-layout-sidebar-spacing-x * 0.5;
      }
    }
  }

  .actions-toolbar {
    width: 100%;
  }

  .message.info {
    @include ms-icon('check', xs);
    display: flex;
    max-width: 100%;
    align-items: center;
    padding: spacers(2) spacers(4);
    background: theme-color('positive-subtle');
    border: 1px solid theme-color('positive');
    color: theme-color('positive');
    margin-bottom: $input-margin-y;

    &:before {
      color: theme-color('positive');
      padding-right: spacers(3);
    }
  }
}

.items.addresses {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;

  > .item {
    width: 50%;
    min-width: 280px;
    margin-bottom: spacers(4);

    address {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    .action {
      display: inline-block;
      width: auto;
      box-sizing: border-box;
      font-size: 70%;
      padding: 0;

      @include respond-to-down(lg) {
        margin-bottom: spacers(2);
      }

      @include respond-to-up($page-layout-breakpoint) {
        font-size: 70%;
      }

      & + .action {
        margin-left: spacers(4);
      }
    }
  }
}

.account .block.table-wrapper {
  &.additional-addresses {
    thead th,
    tbody td:not(.actions) {
      font-size: 70%;
      padding: spacers(2);
    }
  }
}

.account .block.block-addresses-list .table-additional-addresses-items {
  table-layout: fixed;

  thead th,
  tbody td {
    padding: spacers(2);
    font-size: 80%;

    .actions > .action {
      padding: spacers(2);
    }

    &.col.state {
      display: none; /* making extra room as very limited space */
    }
  }
  thead th.actions {
    width: auto;
  }
}

.customer-addresses-toolbar {
  width: 100%;
}
