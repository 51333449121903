@charset "UTF-8";
/*
  This mixin can be used to set the object-fit:
  @include object-fit(contain);
  or object-fit and object-position:
  @include object-fit(cover, top);
*/
.customer-account-login .page-title, .form-create-account .legend {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.customer-account-layout-col1, .block-authentication .block-customer-login,
.login-container .block-customer-login, .customer-account-layout-col2, .block-authentication .block-new-customer,
.login-container .block-new-customer, .form-create-account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  max-height: 100%;
}

/**
 * Loader to be used across the site
 * Use this variable
 */
/**
 * Form Elements config
 */
/**
 * Collection of generic/global variables used across the theme
 */
.block-authentication .block-title,
.login-container .block-title, .customer-account-layout .page-title, .account:not(.page-print) .page-title, .account .page-main .main .block-title > strong, .account .page-main .main .order-title > strong, .shipping-tracking-popup .page-title, .account.page-print .order-title strong {
  font-weight: 700;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  line-height: 1;
}

.block-authentication .block-title,
.login-container .block-title, .customer-account-layout .page-title {
  margin-bottom: 0.5rem;
}

.customer-account-layout, .block-authentication,
.login-container {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .customer-account-layout, .block-authentication,
  .login-container {
    flex-direction: row;
  }
}

.customer-account-layout-col1, .block-authentication .block-customer-login,
.login-container .block-customer-login {
  padding: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 100%;
  background: #f8f9fa;
}

@media screen and (min-width: 992px) {
  .customer-account-layout-col1, .block-authentication .block-customer-login,
  .login-container .block-customer-login {
    padding: 3rem;
    width: 60%;
  }
}

.customer-account-layout-col2, .block-authentication .block-new-customer,
.login-container .block-new-customer {
  padding: 1rem;
  padding-top: 3rem;
  width: 100%;
  position: relative;
  text-align: center;
  background: url(../../images/customer-account-bg.jpg) no-repeat top left;
  background-size: cover;
}

@media screen and (min-width: 992px) {
  .customer-account-layout-col2, .block-authentication .block-new-customer,
  .login-container .block-new-customer {
    padding: 3rem;
    width: 40%;
    min-height: 70vh;
  }
}

.customer-account-layout-col2:before, .block-authentication .block-new-customer:before,
.login-container .block-new-customer:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 20px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.customer-account-layout-col2:before, .block-authentication .block-new-customer:before,
.login-container .block-new-customer:before {
  position: absolute;
  top: 0;
  right: auto;
  bottom: auto;
  left: 50%;
  width: 48px;
  height: 48px;
  border-radius: 100%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -24px;
  margin-top: -24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

@media screen and (min-width: 992px) {
  .customer-account-layout-col2:before, .block-authentication .block-new-customer:before,
  .login-container .block-new-customer:before {
    position: absolute;
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    content: "";
    margin-left: -24px;
    margin-top: -24px;
  }
}

/**
 * Customer login page/form styles
 */
.block-authentication .block-title,
.login-container .block-title {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
}

.block-authentication .block-title,
.login-container .block-title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .block-authentication .block-title,
  .login-container .block-title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .block-authentication .block-title,
  .login-container .block-title {
    font-size: 25px;
  }
}

.block-authentication .block-new-customer .block-content,
.block-authentication .block-new-customer .block-title,
.block-authentication .block-customer-login .block-content,
.block-authentication .block-customer-login .block-title,
.login-container .block-new-customer .block-content,
.login-container .block-new-customer .block-title,
.login-container .block-customer-login .block-content,
.login-container .block-customer-login .block-title {
  width: 100%;
  max-width: 100%;
}

.block-authentication .block-customer-login .block-content,
.block-authentication .block-customer-login .block-title,
.login-container .block-customer-login .block-content,
.login-container .block-customer-login .block-title {
  width: 470px;
}

.block-authentication .block-new-customer .block-content,
.login-container .block-new-customer .block-content {
  max-width: 40ch;
}

.block-authentication .block-new-customer .block-content > p,
.login-container .block-new-customer .block-content > p {
  color: #767676;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
}

.block-authentication .block-new-customer .block-content > p,
.login-container .block-new-customer .block-content > p {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .block-authentication .block-new-customer .block-content > p,
  .login-container .block-new-customer .block-content > p {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .block-authentication .block-new-customer .block-content > p,
  .login-container .block-new-customer .block-content > p {
    font-size: 20px;
  }
}

.block-authentication .block-new-customer .block-title,
.login-container .block-new-customer .block-title {
  position: relative;
  margin-bottom: 1rem;
}

.block-authentication .block-new-customer .block-title:before,
.login-container .block-new-customer .block-title:before {
  display: block;
  position: relative;
  height: 0;
  width: 50%;
  padding-top: 13.65%;
  content: '';
  background: url(../../images/logo.svg) no-repeat center center;
  background-size: contain;
  margin: 0 auto 0.5rem auto;
}

.block-authentication {
  flex-direction: column-reverse;
}

@media screen and (min-width: 992px) {
  .block-authentication {
    flex-direction: row-reverse;
  }
}

.block-authentication .block-title {
  margin-bottom: 1.5rem;
}

.block-authentication .block-content > ul {
  padding: 0;
  margin: 1rem;
}

.block-authentication .block-content > ul > li:after {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.block-authentication .block-content > ul > li:after {
  display: inline-block;
  position: relative;
  top: 1px;
  padding-left: 0.5rem;
}

.block-authentication .block-content > ul > li:not(:last-child) {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 767.98px) {
  .block-authentication .action.action-login {
    max-width: 100%;
    box-sizing: border-box;
  }
}

.form-create-account > .login > .note,
.form-login > .login > .note {
  color: #767676;
}

.form-create-account > .login > .note,
.form-login > .login > .note {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .form-create-account > .login > .note,
  .form-login > .login > .note {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .form-create-account > .login > .note,
  .form-login > .login > .note {
    font-size: 20px;
  }
}

@media screen and (min-width: 768px) {
  .form-create-account .actions-toolbar,
  .form-login .actions-toolbar {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 768px) {
  .form-create-account .actions-toolbar .secondary,
  .form-login .actions-toolbar .secondary {
    flex: none;
    text-align: left;
  }
}

.form-create-account .remind,
.form-login .remind {
  display: inline-block;
  font-size: 80%;
}

@media screen and (min-width: 768px) {
  .form-create-account .remind,
  .form-login .remind {
    margin-left: 30px;
    font-size: 100%;
  }
}

@media screen and (max-width: 767.98px) {
  .block-authentication .form-create-account .secondary > .action, .block-authentication
  .form-login .secondary > .action {
    display: inline-block;
    box-sizing: border-box;
    font-size: 80%;
  }
}

.form-create-account .secondary:not(.action-login),
.form-login .secondary:not(.action-login) {
  width: auto;
}

@media screen and (max-width: 767.98px) {
  .form-create-account .secondary:not(.action-login) .action,
  .form-login .secondary:not(.action-login) .action {
    margin: 0 0 0 auto;
  }
}

.form.reset {
  width: 100%;
  max-width: 470px;
}

.customer-account-createpassword .page-title {
  margin-bottom: 1rem;
}

.form-create-account {
  width: 470px;
}

.form-create-account .field-recaptcha {
  margin-bottom: 1rem;
}

.form-create-account > *,
.form-create-account .actions-toolbar {
  width: 100%;
}

.form-create-account .actions-toolbar > .secondary .action.back {
  display: none;
}

.customer-account-layout .page-title {
  font-family: "Univers Blackest", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  letter-spacing: -0.02em;
  text-align: left;
  width: 100%;
}

.customer-account-layout .page-title {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .customer-account-layout .page-title {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .customer-account-layout .page-title {
    font-size: 25px;
  }
}

.customer-account-layout .page-title-wrapper {
  width: 100%;
  max-width: 470px;
}

.account:not(.page-print) .column.main {
  padding-left: 1rem;
  padding-right: 1rem;
  background: #f8f9fa;
}

@media screen and (min-width: 992px) {
  .account:not(.page-print) .column.main {
    padding-left: 3rem;
    padding-right: 3rem;
    width: calc(100% - 400px);
  }
}

.account:not(.page-print) .sidebar-container {
  background: #fff;
}

@media screen and (min-width: 992px) {
  .account:not(.page-print) .sidebar-container {
    position: sticky;
    top: 0;
    align-self: flex-start;
    margin-top: 3rem;
    margin-right: 0 !important;
    width: auto !important;
    max-width: 300px;
  }
}

.account:not(.page-print) .page-title {
  padding: 1rem 0;
  letter-spacing: 0;
  font-weight: normal;
  text-align: center;
  margin-right: 1.5rem;
}

.account:not(.page-print) .page-title {
  font-size: 20px;
}

@media screen and (min-width: 0px) {
  .account:not(.page-print) .page-title {
    font-size: calc(20px + 19 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .account:not(.page-print) .page-title {
    font-size: 39px;
  }
}

@media screen and (min-width: 768px) {
  .account:not(.page-print) .page-title {
    text-align: left;
  }
}

.account:not(.page-print) .page-title-wrapper .order-status,
.account:not(.page-print) .page-title-wrapper .page-title {
  display: inline-block;
}

.account .page-main .main .block-title,
.account .page-main .main .order-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 53px;
  margin-bottom: 0.5rem;
  border-bottom: 1px solid #e9ecef;
  color: #1a1a1a;
}

.account .page-main .main .block-title > strong, .account .page-main .main .order-title > strong {
  display: flex;
  align-items: center;
  padding-bottom: 0;
  font-weight: normal;
  letter-spacing: -0.02em;
}

.account .page-main .main .block-title > strong, .account .page-main .main .order-title > strong {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .account .page-main .main .block-title > strong, .account .page-main .main .order-title > strong {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .account .page-main .main .block-title > strong, .account .page-main .main .order-title > strong {
    font-size: 25px;
  }
}

.account .page-main .main .block-title > strong:before, .account .page-main .main .order-title > strong:before {
  color: #767676;
  margin-right: 0.5rem;
}

.account .page-main .main .order.block-title > strong:before, .account .page-main .main .order.order-title > strong:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 32px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.account .page-main .block-title .action,
.account .page-main .block-content .action {
  font-size: 75%;
}

.account .box-actions .action {
  width: auto;
}

.account .item-edit-panel {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .account .item-edit-panel {
    width: auto;
  }
}

.account .item-edit-panel > span {
  display: flex;
  text-transform: none;
}

.account .page-main .actions-toolbar {
  margin-bottom: 1.5rem;
}

.block-addresses-default,
.block-addresses-list,
.block-order-details-view,
[class^='block-dashboard-'],
[class*=' block-dashboard-'] {
  color: #1a1a1a;
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
}

.block-addresses-default .block-content,
.block-addresses-list .block-content,
.block-order-details-view .block-content,
[class^='block-dashboard-'] .block-content,
[class*=' block-dashboard-'] .block-content {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  line-height: normal;
}

.block-addresses-default .block-content .table-wrapper,
.block-addresses-list .block-content .table-wrapper,
.block-order-details-view .block-content .table-wrapper,
[class^='block-dashboard-'] .block-content .table-wrapper,
[class*=' block-dashboard-'] .block-content .table-wrapper {
  width: 100%;
}

.block-addresses-default .block-content > .box,
.block-addresses-list .block-content > .box,
.block-order-details-view .block-content > .box,
[class^='block-dashboard-'] .block-content > .box,
[class*=' block-dashboard-'] .block-content > .box {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .block-addresses-default .block-content > .box,
  .block-addresses-list .block-content > .box,
  .block-order-details-view .block-content > .box,
  [class^='block-dashboard-'] .block-content > .box,
  [class*=' block-dashboard-'] .block-content > .box {
    width: 50%;
  }
}

@media screen and (max-width: 992px) {
  .block-addresses-default .block-content > .box,
  .block-addresses-list .block-content > .box,
  .block-order-details-view .block-content > .box,
  [class^='block-dashboard-'] .block-content > .box,
  [class*=' block-dashboard-'] .block-content > .box {
    margin-bottom: 1.5rem;
  }
}

.block-addresses-default .block-content > .box:last-child,
.block-addresses-list .block-content > .box:last-child,
.block-order-details-view .block-content > .box:last-child,
[class^='block-dashboard-'] .block-content > .box:last-child,
[class*=' block-dashboard-'] .block-content > .box:last-child {
  margin-bottom: 0;
}

.block-addresses-default .block-content .box-title,
.block-addresses-list .block-content .box-title,
.block-order-details-view .block-content .box-title,
[class^='block-dashboard-'] .block-content .box-title,
[class*=' block-dashboard-'] .block-content .box-title {
  padding-bottom: 0.5rem;
  display: block;
}

.block-addresses-default .block-content .box-title,
.block-addresses-list .block-content .box-title,
.block-order-details-view .block-content .box-title,
[class^='block-dashboard-'] .block-content .box-title,
[class*=' block-dashboard-'] .block-content .box-title {
  font-size: 10px;
}

@media screen and (min-width: 0px) {
  .block-addresses-default .block-content .box-title,
  .block-addresses-list .block-content .box-title,
  .block-order-details-view .block-content .box-title,
  [class^='block-dashboard-'] .block-content .box-title,
  [class*=' block-dashboard-'] .block-content .box-title {
    font-size: calc(10px + 10 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .block-addresses-default .block-content .box-title,
  .block-addresses-list .block-content .box-title,
  .block-order-details-view .block-content .box-title,
  [class^='block-dashboard-'] .block-content .box-title,
  [class*=' block-dashboard-'] .block-content .box-title {
    font-size: 20px;
  }
}

@media screen and (max-width: 992px) {
  .block-addresses-default .block-content .box-title,
  .block-addresses-list .block-content .box-title,
  .block-order-details-view .block-content .box-title,
  [class^='block-dashboard-'] .block-content .box-title,
  [class*=' block-dashboard-'] .block-content .box-title {
    font-size: 1rem;
  }
}

@media screen and (min-width: 992px) {
  .block-addresses-default .block-content .box-title,
  .block-addresses-list .block-content .box-title,
  .block-order-details-view .block-content .box-title,
  [class^='block-dashboard-'] .block-content .box-title,
  [class*=' block-dashboard-'] .block-content .box-title {
    font-weight: normal;
  }
}

.block-addresses-default .block-content .box-content,
.block-addresses-list .block-content .box-content,
.block-order-details-view .block-content .box-content,
[class^='block-dashboard-'] .block-content .box-content,
[class*=' block-dashboard-'] .block-content .box-content {
  font-size: 90%;
}

.block-addresses-default .block-content .box-content address,
.block-addresses-default .block-content .box-content p,
.block-addresses-list .block-content .box-content address,
.block-addresses-list .block-content .box-content p,
.block-order-details-view .block-content .box-content address,
.block-order-details-view .block-content .box-content p,
[class^='block-dashboard-'] .block-content .box-content address,
[class^='block-dashboard-'] .block-content .box-content p,
[class*=' block-dashboard-'] .block-content .box-content address,
[class*=' block-dashboard-'] .block-content .box-content p {
  margin-bottom: 0;
}

.block-addresses-default .block-content .box-actions .action,
.block-addresses-list .block-content .box-actions .action,
.block-order-details-view .block-content .box-actions .action,
[class^='block-dashboard-'] .block-content .box-actions .action,
[class*=' block-dashboard-'] .block-content .box-actions .action {
  padding: 0;
  font-size: 70%;
  margin-top: 1rem;
  margin-right: 1.5rem;
}

.block-dashboard-addresses > .block-title strong {
  padding-right: 1rem;
}

.block-dashboard-addresses > .block-title .action {
  padding-left: 0;
  padding-right: 0;
}

@media screen and (min-width: 430px) {
  .block-dashboard-addresses > .block-title .action {
    max-width: none;
  }
}

.form-address-edit,
.form-address-new {
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  flex-direction: column;
}

@media screen and (min-width: 992px) {
  .form-address-edit,
  .form-address-new {
    flex-direction: row;
  }
}

@media screen and (min-width: 992px) {
  .form-address-edit > .fieldset,
  .form-address-new > .fieldset {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .form-address-edit > .fieldset:nth-child(1),
  .form-address-new > .fieldset:nth-child(1) {
    padding-right: 1.5rem;
  }
}

@media screen and (min-width: 992px) {
  .form-address-edit > .fieldset:nth-child(2),
  .form-address-new > .fieldset:nth-child(2) {
    padding-left: 1.5rem;
  }
}

.form-address-edit .actions-toolbar,
.form-address-new .actions-toolbar {
  width: 100%;
}

.form-address-edit .message.info,
.form-address-new .message.info {
  display: flex;
  max-width: 100%;
  align-items: center;
  padding: 0.5rem 1.5rem;
  background: #e0ffcf;
  border: 1px solid #03a84e;
  color: #03a84e;
  margin-bottom: 1.5rem;
}

.form-address-edit .message.info:before,
.form-address-new .message.info:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: 14px;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.form-address-edit .message.info:before,
.form-address-new .message.info:before {
  color: #03a84e;
  padding-right: 1rem;
}

.items.addresses {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
}

.items.addresses > .item {
  width: 50%;
  min-width: 280px;
  margin-bottom: 1.5rem;
}

.items.addresses > .item address {
  margin-bottom: 0;
}

.items.addresses > .item:last-child {
  margin-bottom: 0;
}

.items.addresses > .item .action {
  display: inline-block;
  width: auto;
  box-sizing: border-box;
  font-size: 70%;
  padding: 0;
}

@media screen and (max-width: 992px) {
  .items.addresses > .item .action {
    margin-bottom: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .items.addresses > .item .action {
    font-size: 70%;
  }
}

.items.addresses > .item .action + .action {
  margin-left: 1.5rem;
}

.account .block.table-wrapper.additional-addresses thead th,
.account .block.table-wrapper.additional-addresses tbody td:not(.actions) {
  font-size: 70%;
  padding: 0.5rem;
}

.account .block.block-addresses-list .table-additional-addresses-items {
  table-layout: fixed;
}

.account .block.block-addresses-list .table-additional-addresses-items thead th,
.account .block.block-addresses-list .table-additional-addresses-items tbody td {
  padding: 0.5rem;
  font-size: 80%;
}

.account .block.block-addresses-list .table-additional-addresses-items thead th .actions > .action,
.account .block.block-addresses-list .table-additional-addresses-items tbody td .actions > .action {
  padding: 0.5rem;
}

.account .block.block-addresses-list .table-additional-addresses-items thead th.col.state,
.account .block.block-addresses-list .table-additional-addresses-items tbody td.col.state {
  display: none;
  /* making extra room as very limited space */
}

.account .block.block-addresses-list .table-additional-addresses-items thead th.actions {
  width: auto;
}

.customer-addresses-toolbar {
  width: 100%;
}

.account-nav {
  padding: 1rem 0;
  background: #eee;
  color: #767676;
}

.account-nav .items {
  margin-bottom: 0;
}

.account-nav li {
  display: block;
  padding: 0.5rem 2rem;
}

.account-nav .delimiter {
  display: block;
  width: 100%;
  height: 0;
  border-bottom: 1px solid #d5d5d5;
  background: red;
}

.account-nav a {
  display: inline-block;
  white-space: normal;
  /* set to normal rather than nowrap to stop adding right margin on mobile and making text fall off screen */
  color: #767676;
}

.account-nav .current {
  background: #f8f9fa;
  color: #646000;
  border-left: 4px solid #646000;
}

.account-nav .current > a {
  color: #646000;
}

.pager {
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  margin: 1rem 0;
}

.pager::after {
  display: block;
  clear: both;
  content: "";
}

.pager .toolbar-amount {
  margin-bottom: 0;
  width: auto;
  flex: 1 0 auto;
}

.pager .limiter {
  width: auto;
}

.pager .limiter .limiter-label {
  padding-right: 0.5rem;
}

.pager .limiter .limiter-text {
  padding-left: 0.5rem;
}

.actions-toolbar {
  margin: 1.5rem 0 1.5rem;
}

.actions-toolbar > .secondary {
  margin: 0;
}

.actions-toolbar > .secondary .action.back {
  display: inline-block;
  font-size: 75%;
}

.table-reviews,
.table-order-items,
.table-downloadable-products,
.table-additional-addresses-items {
  width: 100%;
}

.table-reviews .col.actions,
.table-order-items .col.actions,
.table-downloadable-products .col.actions,
.table-additional-addresses-items .col.actions {
  text-align: center;
}

@media screen and (min-width: 1200px) {
  .table-reviews .col.actions,
  .table-order-items .col.actions,
  .table-downloadable-products .col.actions,
  .table-additional-addresses-items .col.actions {
    width: 332px;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews th,
  .table-order-items th,
  .table-downloadable-products th,
  .table-additional-addresses-items th {
    display: none;
  }
}

.table-reviews td,
.table-reviews th,
.table-order-items td,
.table-order-items th,
.table-downloadable-products td,
.table-downloadable-products th,
.table-additional-addresses-items td,
.table-additional-addresses-items th {
  font-size: 90%;
}

@media screen and (max-width: 1200px) {
  .table-reviews tbody,
  .table-order-items tbody,
  .table-downloadable-products tbody,
  .table-additional-addresses-items tbody {
    text-align: center;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews tbody tr,
  .table-order-items tbody tr,
  .table-downloadable-products tbody tr,
  .table-additional-addresses-items tbody tr {
    display: inline-block;
    max-width: none;
    width: 100%;
    margin-top: 0.5rem;
    padding: 1rem;
    background: #fff;
  }
  .table-reviews tbody tr:last-child,
  .table-order-items tbody tr:last-child,
  .table-downloadable-products tbody tr:last-child,
  .table-additional-addresses-items tbody tr:last-child {
    margin-bottom: 0.5rem;
  }
}

.table-reviews tfoot td,
.table-reviews tbody td,
.table-order-items tfoot td,
.table-order-items tbody td,
.table-downloadable-products tfoot td,
.table-downloadable-products tbody td,
.table-additional-addresses-items tfoot td,
.table-additional-addresses-items tbody td {
  color: #767676;
}

@media screen and (max-width: 1200px) {
  .table-reviews tfoot td,
  .table-reviews tbody td,
  .table-order-items tfoot td,
  .table-order-items tbody td,
  .table-downloadable-products tfoot td,
  .table-downloadable-products tbody td,
  .table-additional-addresses-items tfoot td,
  .table-additional-addresses-items tbody td {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    max-width: 100%;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews tfoot td:before,
  .table-reviews tbody td:before,
  .table-order-items tfoot td:before,
  .table-order-items tbody td:before,
  .table-downloadable-products tfoot td:before,
  .table-downloadable-products tbody td:before,
  .table-additional-addresses-items tfoot td:before,
  .table-additional-addresses-items tbody td:before {
    content: attr(data-th);
    display: inline;
    padding-right: 0.5rem;
    font-weight: bold;
  }
}

@media screen and (min-width: 1200px) {
  .table-reviews tfoot td.id,
  .table-reviews tbody td.id,
  .table-order-items tfoot td.id,
  .table-order-items tbody td.id,
  .table-downloadable-products tfoot td.id,
  .table-downloadable-products tbody td.id,
  .table-additional-addresses-items tfoot td.id,
  .table-additional-addresses-items tbody td.id {
    padding-left: 0.5rem;
  }
}

.table-reviews tfoot td.actions:before,
.table-reviews tbody td.actions:before,
.table-order-items tfoot td.actions:before,
.table-order-items tbody td.actions:before,
.table-downloadable-products tfoot td.actions:before,
.table-downloadable-products tbody td.actions:before,
.table-additional-addresses-items tfoot td.actions:before,
.table-additional-addresses-items tbody td.actions:before {
  display: none;
}

.table-reviews tfoot td .action,
.table-reviews tbody td .action,
.table-order-items tfoot td .action,
.table-order-items tbody td .action,
.table-downloadable-products tfoot td .action,
.table-downloadable-products tbody td .action,
.table-additional-addresses-items tfoot td .action,
.table-additional-addresses-items tbody td .action {
  display: inline-block;
  width: auto;
  box-sizing: border-box;
  font-size: 80%;
}

@media screen and (min-width: 992px) {
  .table-reviews tfoot td .action,
  .table-reviews tbody td .action,
  .table-order-items tfoot td .action,
  .table-order-items tbody td .action,
  .table-downloadable-products tfoot td .action,
  .table-downloadable-products tbody td .action,
  .table-additional-addresses-items tfoot td .action,
  .table-additional-addresses-items tbody td .action {
    font-size: 70%;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews tfoot td .action,
  .table-reviews tbody td .action,
  .table-order-items tfoot td .action,
  .table-order-items tbody td .action,
  .table-downloadable-products tfoot td .action,
  .table-downloadable-products tbody td .action,
  .table-additional-addresses-items tfoot td .action,
  .table-additional-addresses-items tbody td .action {
    padding-left: 0;
    padding-right: 0;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews tfoot td,
  .table-order-items tfoot td,
  .table-downloadable-products tfoot td,
  .table-additional-addresses-items tfoot td {
    padding: 1rem;
  }
}

.table-reviews th.actions,
.table-order-items th.actions,
.table-downloadable-products th.actions,
.table-additional-addresses-items th.actions {
  opacity: 0;
}

.table-reviews tbody > tr:focus, .table-reviews tbody > tr:hover,
.table-order-items tbody > tr:focus,
.table-order-items tbody > tr:hover,
.table-downloadable-products tbody > tr:focus,
.table-downloadable-products tbody > tr:hover,
.table-additional-addresses-items tbody > tr:focus,
.table-additional-addresses-items tbody > tr:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.table-reviews tbody > tr:focus td, .table-reviews tbody > tr:hover td,
.table-order-items tbody > tr:focus td,
.table-order-items tbody > tr:hover td,
.table-downloadable-products tbody > tr:focus td,
.table-downloadable-products tbody > tr:hover td,
.table-additional-addresses-items tbody > tr:focus td,
.table-additional-addresses-items tbody > tr:hover td {
  color: #000;
}

@media screen and (min-width: 1200px) {
  .table-reviews tbody > tr:focus td, .table-reviews tbody > tr:hover td,
  .table-order-items tbody > tr:focus td,
  .table-order-items tbody > tr:hover td,
  .table-downloadable-products tbody > tr:focus td,
  .table-downloadable-products tbody > tr:hover td,
  .table-additional-addresses-items tbody > tr:focus td,
  .table-additional-addresses-items tbody > tr:hover td {
    background: #fff;
  }
}

.table-reviews .table-caption,
.table-order-items .table-caption,
.table-downloadable-products .table-caption,
.table-additional-addresses-items .table-caption {
  display: none;
}

@media screen and (max-width: 1200px) {
  .table-reviews .item.options,
  .table-reviews .item-options,
  .table-order-items .item.options,
  .table-order-items .item-options,
  .table-downloadable-products .item.options,
  .table-downloadable-products .item-options,
  .table-additional-addresses-items .item.options,
  .table-additional-addresses-items .item-options {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews .item.options dd,
  .table-reviews .item.options dt,
  .table-reviews .item-options dd,
  .table-reviews .item-options dt,
  .table-order-items .item.options dd,
  .table-order-items .item.options dt,
  .table-order-items .item-options dd,
  .table-order-items .item-options dt,
  .table-downloadable-products .item.options dd,
  .table-downloadable-products .item.options dt,
  .table-downloadable-products .item-options dd,
  .table-downloadable-products .item-options dt,
  .table-additional-addresses-items .item.options dd,
  .table-additional-addresses-items .item.options dt,
  .table-additional-addresses-items .item-options dd,
  .table-additional-addresses-items .item-options dt {
    margin: 0;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews .item.options dd,
  .table-reviews .item-options dd,
  .table-order-items .item.options dd,
  .table-order-items .item-options dd,
  .table-downloadable-products .item.options dd,
  .table-downloadable-products .item-options dd,
  .table-additional-addresses-items .item.options dd,
  .table-additional-addresses-items .item-options dd {
    margin-left: 0.5rem;
  }
}

@media screen and (max-width: 1200px) {
  .table-reviews .item.options dd + dt,
  .table-reviews .item-options dd + dt,
  .table-order-items .item.options dd + dt,
  .table-order-items .item-options dd + dt,
  .table-downloadable-products .item.options dd + dt,
  .table-downloadable-products .item-options dd + dt,
  .table-additional-addresses-items .item.options dd + dt,
  .table-additional-addresses-items .item-options dd + dt {
    margin-left: 1rem;
  }
}

.table-reviews th,
.table-reviews td,
.table-downloadable-products th,
.table-downloadable-products td,
.table-additional-addresses-items th,
.table-additional-addresses-items td {
  padding: 1rem;
}

.order-status {
  border: 2px solid #1BA8E9;
  padding: 0.25rem 0.5rem;
  vertical-align: middle;
  position: relative;
  top: -8px;
  border-radius: 8px;
}

.order-actions-toolbar .actions {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
}

.order-actions-toolbar .actions .action {
  padding: .5rem;
  font-size: 80%;
}

.block-order-details-view {
  margin-top: 1.5rem;
}

.block-order-details-view .block-content > .box {
  margin-bottom: 1.5rem;
  width: 100%;
  min-width: 0;
}

@media screen and (min-width: 1200px) {
  .block-order-details-view .block-content > .box {
    width: 50%;
  }
}

.block-order-details-view .payment-method.checkmemo {
  border-bottom: 0;
  padding: 0;
}

.order-date {
  margin-bottom: 1rem;
}

.items.order-links {
  display: flex;
  margin: 0;
  padding: 0;
  flex-direction: column;
}

@media screen and (min-width: 768px) {
  .items.order-links {
    flex-direction: row;
  }
}

.items.order-links > .item:nth-child(1) {
  order: 1;
}

.items.order-links > .item:nth-child(2) {
  order: 2;
}

.items.order-links > .item:nth-child(3) {
  order: 3;
}

.items.order-links > .item:nth-child(4) {
  order: 4;
}

@media screen and (max-width: 768px) {
  .items.order-links > .item:nth-child(1).current, .items.order-links > .item:nth-child(2).current, .items.order-links > .item:nth-child(3).current, .items.order-links > .item:nth-child(4).current {
    order: 5;
  }
}

.items.order-links .nav.item {
  position: relative;
  background: #eee;
  color: #767676;
  padding: 0.5rem 1.5rem;
  border: 1px solid #d5d5d5;
  border-bottom-color: transparent;
}

.items.order-links .nav.item a {
  display: block;
  color: #767676;
}

.items.order-links .nav.item a:hover {
  color: #646000;
  text-decoration: none;
}

.items.order-links .nav.item:after {
  position: absolute;
  top: auto;
  right: 0;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 2px;
  content: '';
  background: #f8f9fa;
  opacity: 0;
}

.items.order-links .nav.item.current {
  background: #f8f9fa;
  color: #646000;
  border-top: 4px solid #646000;
}

.items.order-links .nav.item.current:after {
  opacity: 1;
}

.items.order-links .nav.item.current a {
  color: #646000;
}

.items.order-links + .order-details-items {
  border: 1px solid #d5d5d5;
  margin-bottom: 1.5rem;
  padding: 0.5rem 1.5rem 0 1.5rem;
}

.items.order-links + .order-details-items .action.track,
.items.order-links + .order-details-items .action.print {
  max-width: 290px;
}

.items.order-links + .order-details-items .order-tracking {
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
  color: #767676;
  font-size: 80%;
  justify-content: center;
}

@media screen and (min-width: 1200px) {
  .items.order-links + .order-details-items .order-tracking {
    justify-content: flex-start;
  }
}

.items.order-links + .order-details-items .order-tracking .tracking-title {
  padding-right: 0.5rem;
}

.items.order-links + .order-details-items .order-tracking .tracking-content {
  padding-right: 1.5rem;
}

.items.order-links + .order-details-items .order-tracking dd,
.items.order-links + .order-details-items .order-tracking dt {
  margin: 0;
}

.items.order-links + .order-details-items .actions-toolbar {
  justify-content: flex-end;
}

.items.order-links + .order-details-items .action {
  font-size: 75%;
}

.items.order-links + .order-details-items .order-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  margin: 1.5rem 0;
  min-height: 0;
  border-bottom: 0 none;
}

@media screen and (min-width: 768px) {
  .items.order-links + .order-details-items .order-title {
    flex-direction: row;
  }
}

@media screen and (max-width: 768px) {
  .items.order-links + .order-details-items .order-title .action {
    padding: 0;
  }
}

@media screen and (min-width: 1200px) {
  .items.order-links + .order-details-items thead {
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
  }
}

.items.order-links + .order-details-items thead th {
  padding: 0.5rem 0;
}

.items.order-links + .order-details-items tbody > tr > td.col.name {
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: flex-start;
  text-align: left;
}

.items.order-links + .order-details-items tbody > tr > td.col.name:before {
  display: none;
}

.items.order-links + .order-details-items tbody > tr > td.col.name > * {
  display: block;
  width: 100%;
}

.items.order-links + .order-details-items tbody > tr > td.col.name .item-options > * {
  display: inline;
  margin: 0;
}

.items.order-links + .order-details-items tbody > tr > td.col {
  border-bottom: 1px solid #d5d5d5;
}

@media screen and (max-width: 768px) {
  .items.order-links + .order-details-items tbody > tr > td.col:not(.name) {
    border-bottom: 1px solid #e9e9e9;
  }
}

.items.order-links + .order-details-items tbody > tr > td .price-excluding-tax,
.items.order-links + .order-details-items tbody > tr > td .price-including-tax {
  width: auto;
}

.items.order-links + .order-details-items tbody > tr > td .price-excluding-tax .price,
.items.order-links + .order-details-items tbody > tr > td .price-including-tax .price {
  font-size: 90%;
}

.items.order-links + .order-details-items tbody > tr > td .price-excluding-tax:before,
.items.order-links + .order-details-items tbody > tr > td .price-including-tax:before {
  display: inline;
  top: 0;
}

@media screen and (min-width: 1200px) {
  .items.order-links + .order-details-items tbody > tr > td .price-excluding-tax:before,
  .items.order-links + .order-details-items tbody > tr > td .price-including-tax:before {
    display: flex;
  }
}

@media screen and (min-width: 1200px) {
  .items.order-links + .order-details-items tbody > tr > td {
    padding-left: 0.5rem;
  }
}

.items.order-links + .order-details-items tbody > tr > td ul {
  margin-bottom: 0;
}

@media screen and (max-width: 768px) {
  .items.order-links + .order-details-items tfoot,
  .items.order-links + .order-details-items tbody tr {
    border-bottom: 0;
  }
}

.items.order-links + .order-details-items tfoot {
  background: #eee;
}

.items.order-links + .order-details-items tfoot th {
  text-align: right;
  padding: 0.5rem;
  padding-right: 1rem;
}

.items.order-links + .order-details-items tfoot td {
  width: auto;
  padding: 0.5rem 1rem;
}

.shipping-tracking-popup {
  background: #f8f9fa;
  color: #000;
  padding: 1rem;
}

.shipping-tracking-popup .page-main {
  text-align: center;
}

.shipping-tracking-popup .actions {
  text-align: center;
}

.shipping-tracking-popup .action.close {
  margin: 0 auto;
  font-weight: bold;
  color: #767676;
}

.shipping-tracking-popup .subtitle {
  margin-bottom: 1rem;
}

.shipping-tracking-popup .page-title {
  padding: 0.5rem;
  font-weight: normal;
  background: #ced4da;
}

.shipping-tracking-popup .page-title {
  font-size: 16px;
}

@media screen and (min-width: 0px) {
  .shipping-tracking-popup .page-title {
    font-size: calc(16px + 15 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .shipping-tracking-popup .page-title {
    font-size: 31px;
  }
}

.order.tracking {
  padding: 0.5rem;
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.order.tracking th,
.order.tracking td {
  padding: 0.5rem;
  text-align: center;
  border: 1px solid #e9ecef;
}

@media screen and (max-width: 1200px) {
  .order.tracking th,
  .order.tracking td {
    display: block;
  }
}

@media screen and (max-width: 1200px) {
  .order.tracking th {
    border-bottom: 0 none;
    padding-bottom: 0;
  }
}

@media screen and (max-width: 1200px) {
  .order.tracking td {
    border-top: 0 none;
    padding-top: 0;
  }
}

.order.tracking .table-caption {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  clip-path: inset(50%);
  border: 0;
}

.jp-card.jp-card-ie-10.jp-card-flipped, .jp-card.jp-card-ie-11.jp-card-flipped {
  transform: 0deg;
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-front, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-front {
  transform: rotateY(0deg);
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back {
  transform: rotateY(0deg);
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back:after, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back:after {
  left: 18%;
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-cvc, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-cvc {
  left: 5%;
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny {
  left: 84%;
}

.jp-card.jp-card-ie-10.jp-card-flipped .jp-card-back .jp-card-shiny:after, .jp-card.jp-card-ie-11.jp-card-flipped .jp-card-back .jp-card-shiny:after {
  left: -480%;
  transform: rotateY(180deg);
}

.jp-card.jp-card-ie-10.jp-card-amex .jp-card-back, .jp-card.jp-card-ie-11.jp-card-amex .jp-card-back {
  display: none;
}

.jp-card-logo {
  height: 36px;
  width: 60px;
  font-style: italic;
}

.jp-card-logo, .jp-card-logo:before, .jp-card-logo:after {
  box-sizing: border-box;
}

.jp-card-logo.jp-card-amex {
  text-transform: uppercase;
  font-size: 4px;
  font-weight: bold;
  color: white;
  background-image: repeating-radial-gradient(circle at center, #fff 1px, #999 2px);
  background-image: repeating-radial-gradient(circle at center, #fff 1px, #999 2px);
  border: 1px solid #EEE;
}

.jp-card-logo.jp-card-amex:before, .jp-card-logo.jp-card-amex:after {
  width: 28px;
  display: block;
  position: absolute;
  left: 16px;
}

.jp-card-logo.jp-card-amex:before {
  height: 28px;
  content: "american";
  top: 3px;
  text-align: left;
  padding-left: 2px;
  padding-top: 11px;
  background: #267AC3;
}

.jp-card-logo.jp-card-amex:after {
  content: "express";
  bottom: 11px;
  text-align: right;
  padding-right: 2px;
}

.jp-card.jp-card-amex.jp-card-flipped {
  transform: none;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front:before, .jp-card.jp-card-amex.jp-card-identified .jp-card-back:before {
  background-color: #108168;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front .jp-card-logo.jp-card-amex {
  opacity: 1;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front .jp-card-cvc {
  visibility: visible;
}

.jp-card.jp-card-amex.jp-card-identified .jp-card-front:after {
  opacity: 1;
}

.jp-card-logo.jp-card-discover {
  background: #f60;
  color: #111;
  text-transform: uppercase;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  overflow: hidden;
  z-index: 1;
  padding-top: 9px;
  letter-spacing: .03em;
  border: 1px solid #EEE;
}

.jp-card-logo.jp-card-discover:before, .jp-card-logo.jp-card-discover:after {
  content: " ";
  display: block;
  position: absolute;
}

.jp-card-logo.jp-card-discover:before {
  background: white;
  width: 200px;
  height: 200px;
  border-radius: 200px;
  bottom: -5%;
  right: -80%;
  z-index: -1;
}

.jp-card-logo.jp-card-discover:after {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  top: 10px;
  left: 27px;
  background-color: #f60;
  background-image: radial-gradient(#f60, #fff);
  content: "network";
  font-size: 4px;
  line-height: 24px;
  text-indent: -7px;
}

.jp-card .jp-card-front .jp-card-logo.jp-card-discover {
  right: 12%;
  top: 18%;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-front:before, .jp-card.jp-card-discover.jp-card-identified .jp-card-back:before {
  background-color: #86B8CF;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-logo.jp-card-discover {
  opacity: 1;
}

.jp-card.jp-card-discover.jp-card-identified .jp-card-front:after {
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  transition: 400ms;
  content: " ";
  display: block;
  background-color: #f60;
  background-image: linear-gradient(#f60, #ffa366, #f60);
  height: 50px;
  width: 50px;
  border-radius: 25px;
  position: absolute;
  left: 100%;
  top: 15%;
  margin-left: -25px;
  box-shadow: inset 1px 1px 3px 1px rgba(0, 0, 0, 0.5);
}

.jp-card-logo.jp-card-visa {
  text-transform: uppercase;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 24px;
  line-height: 18px;
  margin-top: 5px;
}

.jp-card-logo.jp-card-visa:before, .jp-card-logo.jp-card-visa:after {
  content: " ";
  display: block;
  width: 100%;
  height: 25%;
}

.jp-card-logo.jp-card-visa:before {
  position: absolute;
  left: -4px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 12px 6px 0;
  border-color: transparent #ffffff transparent transparent;
}

.jp-card.jp-card-visa.jp-card-identified .jp-card-front:before, .jp-card.jp-card-visa.jp-card-identified .jp-card-back:before {
  background-color: #191278;
}

.jp-card.jp-card-visa.jp-card-identified .jp-card-logo.jp-card-visa {
  opacity: 1;
  box-shadow: none;
}

.jp-card-logo.jp-card-visaelectron {
  background: white;
  text-transform: uppercase;
  color: #1A1876;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 18px;
}

.jp-card-logo.jp-card-visaelectron:before, .jp-card-logo.jp-card-visaelectron:after {
  content: " ";
  display: block;
  width: 100%;
  height: 25%;
}

.jp-card-logo.jp-card-visaelectron:before {
  background: #1A1876;
}

.jp-card-logo.jp-card-visaelectron:after {
  background: #E79800;
}

.jp-card-logo.jp-card-visaelectron .elec {
  float: right;
  font-family: arial;
  font-size: 9px;
  margin-right: 1px;
  margin-top: -5px;
  text-transform: none;
}

.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-front:before, .jp-card.jp-card-visaelectron.jp-card-identified .jp-card-back:before {
  background-color: #191278;
}

.jp-card.jp-card-visaelectron.jp-card-identified .jp-card-logo.jp-card-visaelectron {
  opacity: 1;
}

.jp-card-logo.jp-card-mastercard {
  color: white;
  font-style: normal;
  text-transform: lowercase;
  font-weight: bold;
  text-align: center;
  font-size: 9px;
  line-height: 84px;
  z-index: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}

.jp-card-logo.jp-card-mastercard:before, .jp-card-logo.jp-card-mastercard:after {
  content: " ";
  display: block;
  width: 36px;
  top: 0;
  position: absolute;
  height: 36px;
  border-radius: 18px;
}

.jp-card-logo.jp-card-mastercard:before {
  left: 0;
  background: #EB001B;
  z-index: -1;
  opacity: 0.9;
}

.jp-card-logo.jp-card-mastercard:after {
  right: 0;
  background: #FF5F00;
  z-index: -2;
}

.jp-card.jp-card-mastercard.jp-card-identified .jp-card-front .jp-card-logo.jp-card-mastercard, .jp-card.jp-card-mastercard.jp-card-identified .jp-card-back .jp-card-logo.jp-card-mastercard {
  box-shadow: none;
}

.jp-card.jp-card-mastercard.jp-card-identified .jp-card-front:before, .jp-card.jp-card-mastercard.jp-card-identified .jp-card-back:before {
  background-color: #0061A8;
}

.jp-card.jp-card-mastercard.jp-card-identified .jp-card-logo.jp-card-mastercard {
  opacity: 1;
}

.jp-card-logo.jp-card-maestro {
  color: white;
  font-style: normal;
  text-transform: lowercase;
  font-weight: bold;
  text-align: center;
  font-size: 14px;
  line-height: 84px;
  z-index: 1;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
}

.jp-card-logo.jp-card-maestro:before, .jp-card-logo.jp-card-maestro:after {
  content: " ";
  display: block;
  width: 36px;
  top: 0;
  position: absolute;
  height: 36px;
  border-radius: 18px;
}

.jp-card-logo.jp-card-maestro:before {
  left: 0;
  background: #EB001B;
  z-index: -2;
}

.jp-card-logo.jp-card-maestro:after {
  right: 0;
  background: #00A2E5;
  z-index: -1;
  opacity: 0.8;
}

.jp-card.jp-card-maestro.jp-card-identified .jp-card-front .jp-card-logo.jp-card-maestro, .jp-card.jp-card-maestro.jp-card-identified .jp-card-back .jp-card-logo.jp-card-maestro {
  box-shadow: none;
}

.jp-card.jp-card-maestro.jp-card-identified .jp-card-front:before, .jp-card.jp-card-maestro.jp-card-identified .jp-card-back:before {
  background-color: #0B2C5F;
}

.jp-card.jp-card-maestro.jp-card-identified .jp-card-logo.jp-card-maestro {
  opacity: 1;
}

.jp-card-logo.jp-card-dankort {
  width: 60px;
  height: 36px;
  padding: 3px;
  border-radius: 8px;
  border: #000 1px solid;
  background-color: #fff;
}

.jp-card-logo.jp-card-dankort .dk {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.jp-card-logo.jp-card-dankort .dk:before {
  background-color: #ED1C24;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 6px;
}

.jp-card-logo.jp-card-dankort .dk:after {
  content: '';
  position: absolute;
  top: 50%;
  margin-top: -7.7px;
  right: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 7px 10px 0;
  border-color: transparent #ED1C24 transparent transparent;
  z-index: 1;
}

.jp-card-logo.jp-card-dankort .d, .jp-card-logo.jp-card-dankort .k {
  position: absolute;
  top: 50%;
  width: 50%;
  display: block;
  height: 15.4px;
  margin-top: -7.7px;
  background: white;
}

.jp-card-logo.jp-card-dankort .d {
  left: 0;
  border-radius: 0 8px 10px 0;
}

.jp-card-logo.jp-card-dankort .d:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  background: #ED1C24;
  border-radius: 2px 4px 6px 0px;
  height: 5px;
  width: 7px;
  margin: -3px 0 0 -4px;
}

.jp-card-logo.jp-card-dankort .k {
  right: 0;
}

.jp-card-logo.jp-card-dankort .k:before, .jp-card-logo.jp-card-dankort .k:after {
  content: '';
  position: absolute;
  right: 50%;
  width: 0;
  height: 0;
  border-style: solid;
  margin-right: -1px;
}

.jp-card-logo.jp-card-dankort .k:before {
  top: 0;
  border-width: 8px 5px 0 0;
  border-color: #ED1C24 transparent transparent transparent;
}

.jp-card-logo.jp-card-dankort .k:after {
  bottom: 0;
  border-width: 0 5px 8px 0;
  border-color: transparent transparent #ED1C24 transparent;
}

.jp-card.jp-card-dankort.jp-card-identified .jp-card-front:before, .jp-card.jp-card-dankort.jp-card-identified .jp-card-back:before {
  background-color: #0055C7;
}

.jp-card.jp-card-dankort.jp-card-identified .jp-card-logo.jp-card-dankort {
  opacity: 1;
}

.jp-card-logo.jp-card-elo {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background: black;
  color: white;
  text-align: center;
  text-transform: lowercase;
  font-size: 21px;
  font-style: normal;
  letter-spacing: 1px;
  font-weight: bold;
  padding-top: 13px;
}

.jp-card-logo.jp-card-elo .e, .jp-card-logo.jp-card-elo .l, .jp-card-logo.jp-card-elo .o {
  display: inline-block;
  position: relative;
}

.jp-card-logo.jp-card-elo .e {
  -webkit-transform: rotate(-15deg);
  -moz-transform: rotate(-15deg);
  -ms-transform: rotate(-15deg);
  -o-transform: rotate(-15deg);
  transform: rotate(-15deg);
}

.jp-card-logo.jp-card-elo .o {
  position: relative;
  display: inline-block;
  width: 12px;
  height: 12px;
  right: 0;
  top: 7px;
  border-radius: 100%;
  background-image: linear-gradient(#ff0 50%, red 50%);
  transform: rotate(40deg);
  text-indent: -9999px;
}

.jp-card-logo.jp-card-elo .o:before {
  content: "";
  position: absolute;
  width: 49%;
  height: 49%;
  background: black;
  border-radius: 100%;
  text-indent: -99999px;
  top: 25%;
  left: 25%;
}

.jp-card.jp-card-elo.jp-card-identified .jp-card-front:before, .jp-card.jp-card-elo.jp-card-identified .jp-card-back:before {
  background-color: #6F6969;
}

.jp-card.jp-card-elo.jp-card-identified .jp-card-logo.jp-card-elo {
  opacity: 1;
}

.jp-card-logo.jp-card-jcb {
  border-radius: 5px 0px 5px 0px;
  -moz-border-radius: 5px 0px 5px 0px;
  -webkit-border-radius: 5px 0px 5px 0px;
  background-color: white;
  font-style: normal;
  color: white;
  width: 50px;
  padding: 2px 0 0 2px;
}

.jp-card-logo.jp-card-jcb > div {
  width: 15px;
  margin-right: 1px;
  display: inline-block;
  text-align: center;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.6);
  border-radius: 5px 0px 5px 0px;
  -moz-border-radius: 5px 0px 5px 0px;
  -webkit-border-radius: 5px 0px 5px 0px;
}

.jp-card-logo.jp-card-jcb > div:before, .jp-card-logo.jp-card-jcb > div:after {
  content: " ";
  display: block;
  height: 8px;
}

.jp-card-logo.jp-card-jcb > div.j {
  background-color: #000063;
  background-image: linear-gradient(to right, #000063, #008cff);
}

.jp-card-logo.jp-card-jcb > div.c {
  background-color: #630000;
  background-image: linear-gradient(to right, #630000, #ff008d);
}

.jp-card-logo.jp-card-jcb > div.b {
  background-color: #006300;
  background-image: linear-gradient(to right, #006300, lime);
}

.jp-card.jp-card-jcb.jp-card-identified .jp-card-front:before, .jp-card.jp-card-jcb.jp-card-identified .jp-card-back:before {
  background-color: #CB8000;
}

.jp-card.jp-card-jcb.jp-card-identified .jp-card-logo.jp-card-jcb {
  opacity: 1;
  box-shadow: none;
}

.jp-card-logo.jp-card-dinersclub {
  font-family: serif;
  height: 40px;
  width: 100px;
  color: white;
  font-size: 17px;
  font-style: normal;
  letter-spacing: 1px;
}

.jp-card-logo.jp-card-dinersclub::before, .jp-card-logo.jp-card-dinersclub::after {
  display: block;
  position: relative;
}

.jp-card-logo.jp-card-dinersclub::before {
  content: 'Diners Club';
}

.jp-card-logo.jp-card-dinersclub::after {
  content: 'International';
  text-transform: uppercase;
  font-size: 0.6em;
}

.jp-card.jp-card-dinersclub .jp-card-front .jp-card-logo {
  box-shadow: none !important;
}

.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-front:before, .jp-card.jp-card-dinersclub.jp-card-identified .jp-card-back:before {
  background-color: #999;
}

.jp-card.jp-card-dinersclub.jp-card-identified .jp-card-logo.jp-card-dinersclub {
  opacity: 1;
}

.jp-card-container {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  width: 350px;
  max-width: 100%;
  height: 200px;
  margin: auto;
  z-index: 1;
  position: relative;
}

.jp-card {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 1;
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 315px;
  border-radius: 10px;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  transition: all 400ms linear;
}

.jp-card > *, .jp-card > *:before, .jp-card > *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: inherit;
}

.jp-card.jp-card-flipped {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.jp-card .jp-card-front, .jp-card .jp-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-transition: all 400ms linear;
  -moz-transition: all 400ms linear;
  transition: all 400ms linear;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  border-radius: 10px;
  background: #DDD;
}

.jp-card .jp-card-front:before, .jp-card .jp-card-back:before {
  content: " ";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  border-radius: 10px;
  -webkit-transition: all 400ms ease;
  -moz-transition: all 400ms ease;
  transition: all 400ms ease;
}

.jp-card .jp-card-front:after, .jp-card .jp-card-back:after {
  content: " ";
  display: block;
}

.jp-card .jp-card-front .jp-card-display, .jp-card .jp-card-back .jp-card-display {
  color: #fff;
  font-weight: normal;
  opacity: 0.5;
  -webkit-transition: opacity 400ms linear;
  -moz-transition: opacity 400ms linear;
  transition: opacity 400ms linear;
}

.jp-card .jp-card-front .jp-card-display.jp-card-focused, .jp-card .jp-card-back .jp-card-display.jp-card-focused {
  opacity: 1;
  font-weight: 700;
}

.jp-card .jp-card-front .jp-card-cvc, .jp-card .jp-card-back .jp-card-cvc {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 14px;
}

.jp-card .jp-card-front .jp-card-shiny, .jp-card .jp-card-back .jp-card-shiny {
  width: 50px;
  height: 35px;
  border-radius: 5px;
  background: #CCC;
  position: relative;
}

.jp-card .jp-card-front .jp-card-shiny:before, .jp-card .jp-card-back .jp-card-shiny:before {
  content: " ";
  display: block;
  width: 70%;
  height: 60%;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: #d9d9d9;
  position: absolute;
  top: 20%;
}

.jp-card .jp-card-front .jp-card-logo {
  position: absolute;
  opacity: 0;
  right: 5%;
  top: 8%;
  -webkit-transition: 400ms;
  -moz-transition: 400ms;
  transition: 400ms;
}

.jp-card .jp-card-front .jp-card-lower {
  width: 80%;
  position: absolute;
  left: 10%;
  bottom: 30px;
}

@media only screen and (max-width: 480px) {
  .jp-card .jp-card-front .jp-card-lower {
    width: 90%;
    left: 5%;
  }
}

.jp-card .jp-card-front .jp-card-lower .jp-card-cvc {
  visibility: hidden;
  float: right;
  position: relative;
  bottom: 5px;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-number {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 24px;
  clear: both;
  margin-bottom: 30px;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry {
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  letter-spacing: 0em;
  position: relative;
  float: right;
  width: 25%;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before, .jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: bold;
  font-size: 7px;
  white-space: pre;
  display: block;
  opacity: .5;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:before {
  content: attr(data-before);
  margin-bottom: 2px;
  font-size: 7px;
  text-transform: uppercase;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-expiry:after {
  position: absolute;
  content: attr(data-after);
  text-align: right;
  right: 100%;
  margin-right: 5px;
  margin-top: 2px;
  bottom: 0;
}

.jp-card .jp-card-front .jp-card-lower .jp-card-name {
  text-transform: uppercase;
  font-family: "Bitstream Vera Sans Mono", Consolas, Courier, monospace;
  font-size: 20px;
  max-height: 45px;
  position: absolute;
  bottom: 0;
  width: 190px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: horizontal;
  overflow: hidden;
  text-overflow: ellipsis;
}

.jp-card .jp-card-back {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
  -ms-transform: rotateY(180deg);
  -o-transform: rotateY(180deg);
  transform: rotateY(180deg);
}

.jp-card .jp-card-back .jp-card-bar {
  background-color: #444;
  background-image: linear-gradient(#444, #333);
  width: 100%;
  height: 20%;
  position: absolute;
  top: 10%;
}

.jp-card .jp-card-back:after {
  content: " ";
  display: block;
  background-color: #fff;
  background-image: linear-gradient(#fff, #fff);
  width: 80%;
  height: 16%;
  position: absolute;
  top: 40%;
  left: 2%;
}

.jp-card .jp-card-back .jp-card-cvc {
  position: absolute;
  top: 40%;
  left: 85%;
  -webkit-transition-delay: 600ms;
  -moz-transition-delay: 600ms;
  transition-delay: 600ms;
}

.jp-card .jp-card-back .jp-card-shiny {
  position: absolute;
  top: 66%;
  left: 2%;
}

.jp-card .jp-card-back .jp-card-shiny:after {
  content: "This card has been issued by Jesse Pollak and is licensed for anyone to use anywhere for free. It comes with no warranty. For support issues, please visit: github.com/jessepollak/card.";
  position: absolute;
  left: 120%;
  top: 5%;
  color: white;
  font-size: 7px;
  width: 230px;
  opacity: .5;
}

.jp-card.jp-card-identified {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}

.jp-card.jp-card-identified .jp-card-front, .jp-card.jp-card-identified .jp-card-back {
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.5);
}

.jp-card.jp-card-identified .jp-card-front:before, .jp-card.jp-card-identified .jp-card-back:before {
  transition: all 400ms ease;
  opacity: 1;
}

.jp-card.jp-card-identified .jp-card-front .jp-card-logo, .jp-card.jp-card-identified .jp-card-back .jp-card-logo {
  box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.3);
}

.tokens-wrapper {
  margin-bottom: 1rem;
}

.tokens-list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  max-width: 100%;
  flex-wrap: wrap;
}

.token-item {
  margin-bottom: 1rem;
}

@media screen and (min-width: 768px) {
  .token-item {
    margin-right: 0.5rem;
  }
}

@media screen and (min-width: 992px) {
  .token-item {
    margin-right: 1rem;
  }
}

.token-item .action.delete {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  text-align: right;
}

.token-item .action.delete > span:before {
  content: "";
  line-height: 1;
  speak: none;
  font-size: inherit;
  font-style: normal;
  font-weight: normal;
  font-family: "ms-icons";
}

.token-item .action.delete > span:before {
  position: relative;
  top: 2px;
  margin-right: 8px;
}

@media screen and (max-width: 430px) {
  .token-item .jp-card-container {
    width: 270px;
  }
}

@media screen and (max-width: 430px) {
  .token-item .jp-card-container .jp-card {
    min-width: 265px;
  }
}

.account.page-print {
  font-size: 100%;
  line-height: normal;
  margin: 0;
  /* button actions not needed in print mode */
}

.account.page-print .logo {
  display: block;
  margin: 1.5rem 0;
}

.account.page-print .items-qty {
  margin: 0;
}

.account.page-print table {
  font-size: 100%;
}

.account.page-print tfoot,
.account.page-print thead {
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}

.account.page-print thead th,
.account.page-print tfoot th {
  padding: 0.5rem;
}

.account.page-print tfoot th {
  text-align: right;
  padding-right: 1rem;
}

.account.page-print tbody tr {
  padding-left: 0;
  padding-right: 0;
}

.account.page-print tbody tr:hover {
  box-shadow: none;
}

.account.page-print tfoot td {
  padding-left: 0;
  padding-right: 0;
}

.account.page-print tbody td {
  padding: 0.5rem 0;
}

.account.page-print tbody td.col > span {
  width: auto;
}

.account.page-print tbody td .price-excluding-tax .price,
.account.page-print tbody td .price-including-tax .price {
  font-size: inherit;
}

.account.page-print tbody td .price-excluding-tax:before,
.account.page-print tbody td .price-including-tax:before {
  display: inline;
  top: 0;
}

.account.page-print tbody ul,
.account.page-print tbody dl,
.account.page-print tbody ol,
.account.page-print tbody .item-options {
  margin-bottom: 0;
}

.account.page-print tbody ul dt,
.account.page-print tbody ul dd,
.account.page-print tbody dl dt,
.account.page-print tbody dl dd,
.account.page-print tbody ol dt,
.account.page-print tbody ol dd,
.account.page-print tbody .item-options dt,
.account.page-print tbody .item-options dd {
  display: inline;
  margin: 0 0.5rem 0 0;
}

.account.page-print .page-title-wrapper .page-title,
.account.page-print .page-title-wrapper .order-status {
  display: inline-block;
  font-size: 100%;
}

.account.page-print .block-order-details-view {
  margin-top: 0;
}

.account.page-print .page-main .main .order-title {
  border-bottom: 0;
}

.account.page-print .order-title strong {
  display: block;
  padding-bottom: 0;
  font-weight: normal;
  margin-bottom: 0;
}

.account.page-print .order-title strong {
  font-size: 13px;
}

@media screen and (min-width: 0px) {
  .account.page-print .order-title strong {
    font-size: calc(13px + 12 * ((100vw - 0px) / 1440));
  }
}

@media screen and (min-width: 1440px) {
  .account.page-print .order-title strong {
    font-size: 25px;
  }
}

.account.page-print .actions-toolbar {
  display: none;
}

@media screen and (max-width: 991.98px) {
  body.account .page-header {
    height: 111px;
  }
}

body.account:not(.page-print) .page-title-wrapper .page-title {
  text-align: left;
}

/* background-positioning on login,register and forgotpassword pages */
body .block-authentication .block-new-customer,
body .customer-account-layout-col2,
body .login-container .block-new-customer {
  background-position: center;
}

body.account .sidebar-main .account-nav-title {
  display: none !important;
}
