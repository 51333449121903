//------------------------------------//
// Magesparrow - Theme Settings       //
//-----------------------------------//
// Do not remove this.
@import "../../../web/css/magesparrow-theme";
//-----------------------------------//

//------------------------------------//
// Magesparrow - Blank Theme styles   //
//------------------------------------//
// Do not remove this.
@import "magesparrow-blank/Magento_Customer/web/css/module";
//------------------------------------//

body.account .page-header {
    @include respond-to-down($tweakpoint-nav-toggle, $breakpoints, true) {
        height: 111px;
    }
}

body.account:not(.page-print) .page-title-wrapper .page-title {
    text-align: left;
}

/* background-positioning on login,register and forgotpassword pages */
body .block-authentication .block-new-customer,
body .customer-account-layout-col2,
body .login-container .block-new-customer {
    background-position: center;
}

body.account {
    .sidebar-main {
        .account-nav-title {
            display: none !important;
        }
    }
}