.shipping-tracking-popup {
  background: $account-nav-bg-active;
  color: $body-color;
  padding: $container-padding;

  .page-main {
    text-align: center;
  }

  .actions {
    text-align: center;
  }

  .action.close {
    margin: 0 auto;
    font-weight: bold;
    color: $text-muted;
  }

  .subtitle {
    margin-bottom: spacers(3);
  }

  .page-title {
    @extend %u-heading-style;
    @include fluid-type('h3');
    padding: spacers(2);
    font-weight: normal;
    background: $gray-400;
  }

}


.order.tracking {
  padding: spacers(2);
  width: 100%;
  max-width: 460px;
  margin: 0 auto;
  margin-bottom: spacers(5);

  th,
  td {
    padding: spacers(2);
    text-align: center;
    border: 1px solid $gray-200;

    @include respond-to-down($order-table-breakpoint) {
      display: block;
    }
  }

  th {
    @include respond-to-down($order-table-breakpoint) {
      border-bottom: 0 none;
      padding-bottom: 0;
    }
  }

  td {
    @include respond-to-down($order-table-breakpoint) {
      border-top: 0 none;
      padding-top: 0;
    }
  }

  .table-caption {
    @include sr-only();
  }
}