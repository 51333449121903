.actions-toolbar {
  margin: spacers(4) 0 spacers(4);

  > .secondary {
    margin: 0;
  }

  > .secondary .action.back {
    display: inline-block;
    font-size: 75%;
  }
}
