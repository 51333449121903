.account-nav {
  padding: $account-nav-padding;
  background: $account-nav-bg;
  color: $account-nav-color;

  .items {
    margin-bottom: 0;
  }

  li {
    display: block;
    padding: spacers(2) 2.0rem;
  }

  .delimiter {
    display: block;
    width: 100%;
    height: 0;
    border-bottom: $account-nav-seperator;
    background: red;
  }

  a {
    @include animated-underline($account-nav-color);
    display: inline-block;
    white-space: normal; /* set to normal rather than nowrap to stop adding right margin on mobile and making text fall off screen */
    color: $account-nav-color;
  }

  .current {
    background: $account-nav-bg-active;
    color: $account-nav-color-active;
    border-left: 4px solid $account-nav-color-active;

    > a {
      color: $account-nav-color-active;
    }
  }
}

